
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect,useState } from 'react';
import { _getURL } from "@/api/index";
import Redux from "@/action";
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { _postURL } from "@/api/index";
import { Scrollbar, Autoplay } from "swiper";
import { Skeleton } from '@mantine/core';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { ViewButton, PlayButton } from '@/components/common/button';
import { NewsItem, LatestNewsItem, NewsCategory } from '@/components/news';
import { Header } from '@/components/common/header';
import { BottomNavigation } from '../components/common/navigation';
import { LoginModal, PlacementModal } from '../components/modal';
import ScrollContainer from 'react-indiana-drag-scroll';
import { _getNextNewsList, _getNewsCategoryData } from '@/libraries/common';
import { pageLimit, pageLink } from '@/config/site';
import { InView } from 'react-intersection-observer';
import { LoadMore } from '@/components/common/loader';
import { _increaseView, _getAllVehicleTopList } from '@/libraries/common';
import { EmptyData } from '@/components/common/emptyData';
import { RankingItem } from '@/components/ranking';
import Link from 'next/link';
import { PlacementBanner } from '@/components/placement';
import { _getBanner, _getPlacement, _getSpeedTestRanking, _modelName, _numToPrice } from '../libraries/common';
import { VehicleImage } from '../components/common/image';
import { SkeletonBrandItem, SkeletonParagraph, SkeletonSpeedTestRow, SkeletonTag } from '../components/common/skeleton';
import { ExpendMore, MoreIcon } from '../components/common/icon';
import { colorBlueActiveCode, placementArea } from '../config/site';
import { Footer } from '../components/common/footer';
import { getCookie, setCookie } from 'cookies-next';
import moment from 'moment';
import { useRouter } from 'next/router';
import { updatePath } from "@/action";
import { MyBody } from '../components/body/body';
import { CustomLink } from '../components/app/customLink';

const homePageCategory = [
    {label: "new_cars", image: "/assets/images/new_car_icon.svg", link: pageLink.vehicle.homepage},
    {label: "ev_cars", image: "/assets/images/ev_car_icon.svg", link: `${pageLink.vehicle.filterResult}?fuel=EV`},
    {label: "compare", image: "/assets/images/compare_blue_icon.svg", link: pageLink.compare.homepage},
    {label: "car_filter", image: "/assets/images/car_filter_icon.png", link: pageLink.vehicle.filter},
    {label: "calculator", image: "/assets/images/calculator_blue_icon.svg", link: pageLink.calculator.vehicleLoad},
]

const HomePopular = (props) => {
    const { popularTabActive = 0, vehicleTopList = [], t={t}, setPopularTabActive = () => {}, loading = false } = props
    if(loading == true){
        return (
            <div className='homePage_popularBoxOuter'>
                <div className='homePage_popularBox'>
                    <div className='homePage_popularBox_shadow' />
                    <div className='homePage_popularBox_tabList containerPadding'>
                        <div onClick={()=>{setPopularTabActive(0)}} className={`homePage_popularBox_tabItemOuter_brand cursorPointer ${popularTabActive == 0 ? "active" : ""}`}>
                            <div className={`homePage_popularBox_shadowTab ${popularTabActive == 1 ? "homePage_popularBox_shadowTab_modelActive": ""}`} />
                            <div className='homePage_popularBox_tabItem homePage_popularBox_tabItem_brand'>
                                <p className={`fontSize_16 ${popularTabActive == 0 ? "fontWeight_bold" : "fontWeight_semiBold"} fontColor_red homePage_popularBox_tabItem_text`}>
                                    {t('popular_brand')}
                                </p>
                            </div>
                        </div>
                        <div onClick={()=>{setPopularTabActive(1)}} className={`homePage_popularBox_tabItemOuter_model cursorPointer ${popularTabActive == 1 ? "active" : ""}`}>
                            <div className='homePage_popularBox_tabItem homePage_popularBox_tabItem_model'>
                                <p className={`fontSize_16 ${popularTabActive == 1 ? "fontWeight_bold" : "fontWeight_semiBold"} fontColor_white homePage_popularBox_tabItem_text`}>
                                    {t('popular_models')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='containerPadding'>
                        <div className='homePage_popularBox_content homePage_popularBox_content_brand'>
                            {
                                _.times(10, (key)=>(
                                    <SkeletonBrandItem key={key} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {
                !_.isEmpty(vehicleTopList) &&
                <div className='homePage_popularBoxOuter'>
                    <div className='homePage_popularBox'>
                        <div className='homePage_popularBox_shadow' />
                        <div className='homePage_popularBox_tabList containerPadding'>
                            <div onClick={()=>{setPopularTabActive(0)}} className={`homePage_popularBox_tabItemOuter_brand cursorPointer ${popularTabActive == 0 ? "active" : ""}`}>
                                <div className={`homePage_popularBox_shadowTab ${popularTabActive == 1 ? "homePage_popularBox_shadowTab_modelActive": ""}`} />
                                <div className='homePage_popularBox_tabItem homePage_popularBox_tabItem_brand'>
                                    <p className={`fontSize_16 ${popularTabActive == 0 ? "fontWeight_bold" : "fontWeight_semiBold"} fontColor_red homePage_popularBox_tabItem_text`}>
                                        {t('popular_brand')}
                                    </p>
                                </div>
                            </div>
                            <div onClick={()=>{setPopularTabActive(1)}} className={`homePage_popularBox_tabItemOuter_model cursorPointer ${popularTabActive == 1 ? "active" : ""}`}>
                                <div className='homePage_popularBox_tabItem homePage_popularBox_tabItem_model'>
                                    <p className={`fontSize_16 ${popularTabActive == 1 ? "fontWeight_bold" : "fontWeight_semiBold"} fontColor_white homePage_popularBox_tabItem_text`}>
                                        {t('popular_models')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='containerPadding'>
                            {
                                popularTabActive == 0 ?
                                    <div className='homePage_popularBox_content homePage_popularBox_content_brand'>
                                        {
                                            _.reduce(vehicleTopList?.brand, (result, value, key)=>{
                                                if(key <= 8){
                                                    result.push(
                                                        <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl + value.brand_custom_url}`}>
                                                            <div className='homePage_popularBox_brand_item cursorPointer'>
                                                                <div className='homePage_popularBox_brand_item_imageOuter'>
                                                                    <div className="homePage_popularBox_brand_item_image" style={{backgroundImage: `url(${process.env.NEXT_PUBLIC_FS_URL + value.image_path})`}} />
                                                                </div>
                                                                <p className='fontSize_12 homePage_popularBox_brand_item_text'>
                                                                    {value.name}
                                                                </p>
                                                            </div>
                                                        </CustomLink>
                                                    )
                                                }
                                                return result
                                            },[])
                                        }
                                        <CustomLink href={`${pageLink.vehicle.homepage}`}>
                                            <div className='homePage_popularBox_brand_item cursorPointer'>
                                                <div className='homePage_popularBox_brand_item_imageOuter homePage_popularBox_brand_item_imageOuter_moreIcon'>
                                                    {/* <div className="homePage_popularBox_brand_item_image" style={{backgroundImage: `url(/assets/images/more_icon.svg)`}} /> */}
                                                    <MoreIcon color={"#E91C21"} />
                                                </div>
                                                <p className='fontSize_12 homePage_popularBox_brand_item_text'>
                                                    {t('more')}
                                                </p>
                                            </div>
                                        </CustomLink>
                                    </div>
                                :
                                    <div className='homePage_popularBox_content homePage_popularBox_content_model'>
                                        <div className='homePage_popularBox_content_model_transparent'>
                                            <ScrollContainer className='homePage_popularBox_content_model_transparent_vehicleList'>
                                                <div className='homePage_popularBox_content_model_transparent_vehicleWrap containerPadding'>
                                                    {
                                                        _.reduce(vehicleTopList?.model,(result, value, key)=>{
                                                            result.push(
                                                                <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl+value.brand_custom_url}/${value.model_custom_url}`}>
                                                                    <div className='homePage_popularBox_content_model_transparent_vehicleItem cursorPointer'>
                                                                        <div className='homePage_popularBox_content_model_transparent_vehicleItem_image'>
                                                                            <VehicleImage 
                                                                                imagePath={value.image_path}
                                                                                tagType={value.model_tag}
                                                                            />
                                                                        </div>
                                                                        <p className='fontSize_12 fontColor_lightRed'>
                                                                            {value.vehicle_brand_name}
                                                                        </p>
                                                                        <p className='fontSize_12 fontWeight_semiBold fontColor_red'>
                                                                            {_modelName(value.vehicle_brand_name, value.name)}
                                                                        </p>
                                                                    </div>
                                                                </CustomLink>
                                                            )
                                                            return result
                                                        },[])
                                                    }
                                                </div>
                                            </ScrollContainer>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const Banner = (props) => {
    const { data = [], loading = false } = props

    if(loading == true){
        return (
            <div className='homeBanner'>
                <div className='bannerSlideBackground'>
                    <div className='absoluteSkeletonBox'>
                        <Skeleton height={'100%'} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                !_.isEmpty(data) &&
                <div className='homeBanner'>
                    <Swiper
                        slidesPerView={1}
                        scrollbar={{
                            hide: false,
                        }}
                        modules={[Scrollbar, Autoplay]}
                        className="mySwiper"
                        loop={false}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false
                        }}
                    >
                        {
                            _.reduce(data, (result, value, key) => {
                                result.push(
                                    <SwiperSlide key={key}>
                                        <a href={value.url}>
                                        <div className='bannerSlideBackground' style={{backgroundImage: `url('${process.env.NEXT_PUBLIC_FS_URL}${value.image_path}')`}}>
                                            <div className='bannerSlideLinearBackground' />
                                            <div className='fontSize_14 fontWeight_semiBold fontColor_white bannerSlideText'>
                                                {value.name}
                                            </div>
                                        </div>
                                        </a>
                                    </SwiperSlide>
                                )
                                return result;
                            }, [])
                        }
                    </Swiper>
                </div>
            }
        </>
    )
}

const HottestVideo = (props) => {
    const { hottestVideo = [], t, loading = false } = props
    if(loading == true){
        return (
            <>
                <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                <div className='hottestVideo'>
                    <div className='hottestVideoTitle'>
                        <p className='fontSize_16 fontWeight_bold fontColor_dark'>
                            {t('hottest_videos')}
                        </p>
                    </div>
                    <ScrollContainer 
                        className='hottestVideoList'
                        vertical={false}
                        horizontal={true}
                    >
                        <div className='hottestVideoListIniline'>
                            {
                                _.times(3, (key) => (
                                    <div key={key} className='hottestVideoItem'>
                                        <div className='hottestVideoItem_video'>
                                            <div className='absoluteSkeletonBox'>
                                                <Skeleton height={'100%'} radius={'20px'}/>
                                            </div>
                                        </div>
                                        <div className='fontSize_12 fontWeight_semiBold hottestVideoItem_text'>
                                            <SkeletonParagraph row={2} isTitle={true} />
                                        </div>
                                        <div className='hottestVideoItem_view'>
                                            <SkeletonTag />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </ScrollContainer>
                </div>
            </>
        )
    }
    return (
        <>
            {
                !_.isEmpty(hottestVideo) &&
                <>
                    <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                    <div className='hottestVideo'>
                        <div className='hottestVideoTitle'>
                            <p className='fontSize_16 fontWeight_bold fontColor_dark'>
                                {t('hottest_videos')}
                            </p>
                        </div>
                        <ScrollContainer 
                            className='hottestVideoList'
                            vertical={false}
                            horizontal={true}
                        >
                            <div className='hottestVideoListIniline'>
                                {
                                    _.reduce(hottestVideo, (result, value, key) => {
                                        result.push(
                                            <HottestVideoItem data={value} key={key} />
                                        )
                                        return result
                                    },[])
                                }
                            </div>
                        </ScrollContainer>
                    </div>
                </>
            }
        </>
    )
}

const HottestVideoItem = (props) => {
    const { data } = props
    const [opened, setOpened] = useState(false)
    const [view, setView] = useState(data?.views > 0 ? parseInt(data.views) : 0);

    return (
        <>
            <CustomLink href={`${pageLink.news.newsMidUrl}${data?.custom_url}`}>
                <div 
                    onClick={()=>{setOpened(true), _increaseView(data.news_id), setView((prevView)=>{ return prevView + 1})}} 
                    className='hottestVideoItem'
                >
                    <div className='hottestVideoItem_video' style={{backgroundImage: `url(${!_.isEmpty(data?.cover_image) ? process.env.NEXT_PUBLIC_FS_URL + data?.cover_image : data.thumbnail})`}}>
                        <PlayButton 
                            size={'sm'}
                        />
                    </div>
                    <p className='fontSize_12 fontWeight_semiBold hottestVideoItem_text'>
                        {data.title}
                    </p>
                    <div className='hottestVideoItem_view'>
                        <ViewButton type={"red"} views={view} />
                    </div>
                </div>
            </CustomLink>
            {/* <VideoModal 
                opened={opened}
                setOpened={setOpened}
                videoLink={data.youtube_url}
            /> */}
        </>
        
    )
}

const HomePageCategory = (props) => {
    const { t } = props
    return (
        <ScrollContainer className='homePage_category_list'>
            <div className='homePage_category_wrap containerPadding'>
                {
                    _.reduce(homePageCategory, (result, value, key)=>{
                        result.push(
                            <CustomLink key={key} href={value.link}>
                                <div className='homePage_category_item cursorPointer'>
                                    <div className='homePage_category_imageBox'>
                                        <img 
                                            className='w-100'
                                            src={value.image}
                                        />
                                    </div>
                                    <p className='fontSize_12'>
                                        {t(value.label)}
                                    </p>
                                </div>
                            </CustomLink>
                        )
                        return result
                    },[])
                }
                {/* <div onClick={()=>toast.comingSoon()} className='homePage_category_item'>
                    <div className='homePage_category_imageBox'>
                        <img 
                            className='homePage_category_image'
                            src={"/assets/images/calculator_blue_icon.svg"}
                        />
                    </div>
                    <p className='fontSize_12'>
                        {t('calculator')}
                    </p>
                </div> */}
            </div>
        </ScrollContainer>
    )
}

const RecommendedForYou = (props) => {
    const { data, t, loading = false } = props
    if(loading == true){
        return (
            <>
                <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                <div className='homePage_recommandedVehicle'>
                    <div className='homePage_recommandedVehicle_title containerPadding'>
                        <p className='fontSize_16 fontWeight_bold fontColor_dark'>
                            {t('recommended_for_you')}
                        </p>
                    </div>
                    <div className='homePage_recommandedVehicle_content'>
                        <ScrollContainer className='homePage_recommandedVehicle_list'>
                            <div className='homePage_recommandedVehicle_wrap containerPadding'>
                                {
                                    _.times(3, (key)=>(
                                        <div key={key} className='homePage_recommandedVehicle_item'>
                                            <div className='homePage_recommandedVehicle_item_image'>
                                                <VehicleImage loading={true} />
                                            </div>
                                            <div className='fontSize_12 fontColor_lightBlue'>
                                                <SkeletonParagraph row={1}/>
                                            </div>
                                            <div className='fontSize_12 fontWeight_semiBold fontColor_blue'>
                                                <SkeletonParagraph row={1} isTitle={true} />
                                            </div>
                                            <div className='fontSize_12 fontWeight_semiBold fontColor_lightBlue'>
                                                <SkeletonParagraph row={1}/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </ScrollContainer>
                    </div>
                </div>
            </>
        )    
    }
    return (
        <>
            {
                !_.isEmpty(data) &&
                <>
                    <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                    <div className='homePage_recommandedVehicle'>
                        <div className='homePage_recommandedVehicle_title containerPadding'>
                            <p className='fontSize_16 fontWeight_bold fontColor_dark'>
                                {t('recommended_for_you')}
                            </p>
                        </div>
                        <div className='homePage_recommandedVehicle_content'>
                            <ScrollContainer className='homePage_recommandedVehicle_list'>
                                <div className='homePage_recommandedVehicle_wrap containerPadding'>
                                    {
                                        _.reduce(data, (result, value, key)=>{
                                            result.push(
                                                <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl+value.brand_custom_url}/${value.model_custom_url}`}>
                                                    <div className='homePage_recommandedVehicle_item cursorPointer'>
                                                        <div className='homePage_recommandedVehicle_item_image'>
                                                            <VehicleImage 
                                                                imagePath={value.image_path}
                                                            />
                                                        </div>
                                                        <p className='fontSize_12 fontColor_lightBlue'>
                                                            {value.vehicle_brand_name}
                                                        </p>
                                                        <p className='fontSize_12 fontWeight_semiBold fontColor_blue'>
                                                            {_modelName(value.vehicle_brand_name, value.name)}
                                                        </p>
                                                        <p className='fontSize_12 fontWeight_semiBold fontColor_lightBlue'>
                                                            {_numToPrice(value.min_price)}
                                                        </p>
                                                    </div>
                                                </CustomLink>
                                            )
                                            return result
                                        },[])
                                    }
                                </div>
                            </ScrollContainer>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const HomePageSpeedTest = (props) => {
    const {t, rankingList = [], loading = false } = props
    if(loading == true){
        return (
            <>
                <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                <div className='homePage_rankingBoxOuter containerPadding'>
                    <div className='homePage_rankingBox'>
                        <div className='homePage_rankingBox_titleRow'>
                            <div className='homePage_rankingBox_logo' />
                            <p className='fontSize_24 fontWeight_extraBold fontStyle_italic homePage_rankingBox_speedTest'>
                                SPEED TEST
                            </p>
                        </div>
                        {/* <div className='homePage_rankingBox_test'/>
                        <div className='homePage_rankingBox_cup'/> */}
                        <div className='homePage_rankingBox_content'>
                            <>
                                {
                                    _.times(3,(key)=>(
                                        <SkeletonSpeedTestRow key={key} />
                                    ))
                                }
                            </>
                        </div>
                        <div className='homePage_rankingBox_viewMoreBox'>
                            <CustomLink href={pageLink.ranking.homepage}>
                                <div className="viewMore cursorPointer">
                                    <p className='fontSize_14 fontWeight_semiBold fontColor_blue'>
                                        {t('view_more')}
                                    </p>
                                    <div className="viewMore_icon">
                                        <ExpendMore color={colorBlueActiveCode} />
                                    </div>
                                </div>
                            </CustomLink>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            {
                !_.isEmpty(rankingList) && 
                <>
                    <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                    <div className='homePage_rankingBoxOuter containerPadding'>
                        <div className='homePage_rankingBox'>
                            <div className='homePage_rankingBox_titleRow'>
                                <div className='homePage_rankingBox_logo' />
                                <p className='fontSize_24 fontWeight_extraBold fontStyle_italic homePage_rankingBox_speedTest'>
                                    SPEED TEST
                                </p>
                            </div>
                            {/* <div className='homePage_rankingBox_test'/>
                            <div className='homePage_rankingBox_cup'/> */}
                            <div className='homePage_rankingBox_content'>
                                {
                                    _.reduce(rankingList, (result, value, key)=>{
                                        if(key <= 2){
                                            result.push(
                                                <RankingItem key={key} data={value} />
                                            )
                                        }
                                        return result
                                    },[])
                                }
                            </div>
                            <div className='homePage_rankingBox_viewMoreBox'>
                                <CustomLink href={pageLink.ranking.homepage}>
                                    <div className="viewMore cursorPointer">
                                        <p className='fontSize_14 fontWeight_semiBold fontColor_blue'>
                                            {t('view_more')}
                                        </p>
                                        <div className="viewMore_icon">
                                            <ExpendMore color={colorBlueActiveCode} />
                                        </div>
                                    </div>
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default function Index(props) {
    const { t, device, session } = props;
    const router = useRouter();
    const { store, dispatch } = Redux();
    const [loading, setLoading] = useState(true);
    // banner
    const [bannerSlider, setBannerSlider] = useState([]);

    // news category
    const [activeNewsCategory, setActiveNewsCategory] = useState({mainCat: 0, mainCatKey: null, subCat: [], activeCat: 0})

    // news list
    const [totalRow, setTotalRow] = useState(0);
    const [newsList, setNewsList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isLoadingNewsList, setIsLoadingNewList] = useState(false)

    // hottest video
    const [hottestVideoList, setHottestVideoList] = useState([]);

    // popular tab
    const [popularTabActive, setPopularTabActive] = useState(0);

    // pop placement 
    const [placementModalOpen, setPlacementModalOpen] = useState(false)

    const [loginOpened, setLoginOpened] = useState(false)

    const fetchHottestVideo = async() =>{
        try {
            let req = {
                "type": `2,3`,
            }
            let video = await _postURL("/api/news/getHottest", {...req});
            if(!_.isEmpty(video?.data?.data)) {
                setHottestVideoList(video?.data?.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const fetchData = async() => {
            try {
                if (isMounted == true && device != null) {
                    setIsLoadingNewList(true)

                    if(store?.path?.dataBanner?.[t('lang')] == undefined){
                        await _getBanner(dispatch, t('lang'), store)
                    }
                    if(store?.path?.dataAllVehicleTopList == undefined){
                        await _getAllVehicleTopList(dispatch);
                    }
                    if(store?.path?.dataPlacement == undefined){
                        await _getPlacement(dispatch)
                    }
                    if(store?.path?.dataNewsCategory?.[t('lang')] == undefined){
                        await _getNewsCategoryData(dispatch, t('lang'), store)
                    }
                    if(store?.path?.dataSpeedTestRanking == undefined){
                        await _getSpeedTestRanking(dispatch)
                    }
                    
                    await _getNextNewsList({offset, limit: pageLimit}, setNewsList, setTotalRow)
                    await fetchHottestVideo()
                }
            } catch(error){
                console.log(error)
            } finally {
                if (isMounted && device != null) { // Check if the component is still mounted
                    setIsLoadingNewList(false)
                    setLoading(false)
                }
            }
        }

        fetchData();
        return () => {
            isMounted = false;
        };
    }, []);

    // placement pop out 
    useEffect(()=>{
        if(!_.isEmpty(store?.path?.dataPlacement?.[placementArea.homePage_popUp])){
            let homePage_popOutPlacementCookie = getCookie('homePage_popOutPlacement')
            if(homePage_popOutPlacementCookie != true){
                setPlacementModalOpen(true)
                const now = moment().format("YYYY-MM-DD HH:mm:ss");
                const endOfDay = moment().endOf('day');
                const secondsUntilEndOfDay = endOfDay.diff(now, 'seconds');
                setCookie('homePage_popOutPlacement', true, {maxAge: secondsUntilEndOfDay })
            }
        }
    },[store?.path?.dataPlacement])

    //  placement close in 3 sec
    useEffect(()=>{
        if(placementModalOpen == true){
            setTimeout(() => {
                setPlacementModalOpen(false)
            }, "3000");
        }
    },[placementModalOpen])

    useEffect(()=>{
        if(_.isEmpty(store?.path?.loginCheck?.firstTime)){
            if(router?.isReady == true){
                if(!_.isEmpty(router?.query?.ref_code)){
                    setLoginOpened(true)
                    dispatch(updatePath({ key: "loginCheck", path: {firstTime: true, ref_code: router?.query?.ref_code}}));
                } else {
                    dispatch(updatePath({ key: "loginCheck", path: {firstTime: true, ref_code: ""}}));
                }
            }
        }
    },[router?.isReady])

    const _handleLoadMore = async(inView, entry) => {
        if(inView == true && isLoadingNewsList == false){
            setIsLoadingNewList(true)
            await _getNextNewsList({offset: offset + pageLimit, limit: pageLimit}, setNewsList, setTotalRow)
            setOffset((prev)=>prev + pageLimit)
            setIsLoadingNewList(false)
        }
    }

    return (
        <>
            <MyBody
                t={t}
                headerComponent={
                    <Header t={t} data={store?.path?.dataAllVehicleTopList?.model} />
                }
                navigationComponent={
                    <BottomNavigation t={t} />
                }
                showNavigation={false}
                modalComponent={
                    <>
                        <PlacementModal
                            placementModalOpen={placementModalOpen}
                            setPlacementModalOpen={setPlacementModalOpen}
                            t={t}
                        >
                            <PlacementBanner
                                // can't pass object because inside is react memo, pass object wont work the memo
                                loading={store?.path?.dataPlacement == undefined ? true : false}
                                area={placementArea?.homePage_popUp} 
                                placement_id={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.placement_id}
                                type={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.type}
                                image_path={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.image_path}
                                url={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.url}
                                script={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.script}
                            />
                        </PlacementModal>
                        <LoginModal
                            opened={loginOpened}
                            setOpened={setLoginOpened}
                            t={t}
                        />
                    </>
                }
            >
                <Banner 
                    data={store?.path?.dataBanner?.[t('lang')]}
                    loading={store?.path?.dataBanner?.[t('lang')] == undefined ? true : false} 
                    t={t} 
                />
                <HomePageCategory t={t} />
                <HomePopular 
                    t={t}
                    loading={store?.path?.dataAllVehicleTopList == undefined ? true : false}
                    popularTabActive={popularTabActive}
                    setPopularTabActive={setPopularTabActive}
                    vehicleTopList={store?.path?.dataAllVehicleTopList}
                />
                <PlacementBanner
                    // can't pass object because inside is react memo, pass object wont work the memo
                    loading={store?.path?.dataPlacement == undefined ? true : false}
                    area={placementArea?.homePage_afterBrands} 
                    placement_id={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.placement_id}
                    type={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.type}
                    image_path={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.image_path}
                    url={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.url}
                    script={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.script}
                />
                <NewsCategory 
                    data={store?.path?.dataNewsCategory?.[t('lang')]}
                    activeNewsCategory={activeNewsCategory}
                    setActiveNewsCategory={setActiveNewsCategory}
                    subCatOpen={false}
                    isLink={true}
                    t={t}
                    loading={store?.path?.dataNewsCategory?.[t('lang')] == undefined ? true : false}
                />
                <LatestNewsItem data={newsList?.[0]} loading={loading} type={newsList?.[0]?.type} />
                <NewsItem data={newsList?.[1]} loading={loading} type={newsList?.[1]?.type} />
                <NewsItem data={newsList?.[2]} loading={loading} type={newsList?.[2]?.type} />
                <NewsItem data={newsList?.[3]} loading={loading} type={newsList?.[3]?.type} />
                <RecommendedForYou data={store?.path?.dataAllVehicleTopList?.model} loading={loading} t={t} />
                <HottestVideo hottestVideo={hottestVideoList} t={t} loading={loading}/>
                <HomePageSpeedTest t={t} rankingList={store?.path?.dataSpeedTestRanking} loading={store?.path?.dataSpeedTestRanking == undefined ? true : false} />
                {
                    !_.isEmpty(newsList) ?
                        _.reduce(newsList, (result, value, key) => {
                            key > 3 ? 
                            result.push(
                                <div key={key}>
                                    <NewsItem data={value} type={value?.type} />
                                </div> 
                            )
                            :
                            null
                            return result
                        },[])
                        :
                    null
                }
                {
                    _.isEmpty(newsList) &&
                    <EmptyData t={t} isLoading={isLoadingNewsList} />
                }
                {
                    (totalRow > (offset + pageLimit) && isLoadingNewsList == false) &&
                    <InView as="div" onChange={_handleLoadMore}>
                        {/* <LoadMore t={t} /> */}
                    </InView>
                }
                {
                    (isLoadingNewsList == true && totalRow != 0) &&
                    <LoadMore t={t} />
                }
            </MyBody>
        </>
    )

    // return (
    //     <>
    //         <div className='bodyContainer'>
    //             <div className='contentContainer bodyContainerHasBottomNavigation'>
    //                 <Header t={t} data={store?.path?.dataAllVehicleTopList?.model} />
    //                 <Banner 
    //                     data={store?.path?.dataBanner?.[t('lang')]}
    //                     loading={store?.path?.dataBanner?.[t('lang')] == undefined ? true : false} 
    //                     t={t} 
    //                 />
    //                 <HomePageCategory t={t} />
    //                 <HomePopular 
    //                     t={t}
    //                     loading={store?.path?.dataAllVehicleTopList == undefined ? true : false}
    //                     popularTabActive={popularTabActive}
    //                     setPopularTabActive={setPopularTabActive}
    //                     vehicleTopList={store?.path?.dataAllVehicleTopList}
    //                 />
    //                 <PlacementBanner
    //                     // can't pass object because inside is react memo, pass object wont work the memo
    //                     loading={store?.path?.dataPlacement == undefined ? true : false}
    //                     area={placementArea?.homePage_afterBrands} 
    //                     placement_id={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.placement_id}
    //                     type={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.type}
    //                     image_path={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.image_path}
    //                     url={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.url}
    //                     script={store?.path?.dataPlacement?.[placementArea?.homePage_afterBrands]?.script}
    //                 />
    //                 <NewsCategory 
    //                     data={store?.path?.dataNewsCategory?.[t('lang')]}
    //                     activeNewsCategory={activeNewsCategory}
    //                     setActiveNewsCategory={setActiveNewsCategory}
    //                     subCatOpen={false}
    //                     isLink={true}
    //                     t={t}
    //                     loading={store?.path?.dataNewsCategory?.[t('lang')] == undefined ? true : false}
    //                 />
    //                 <LatestNewsItem data={newsList?.[0]} loading={loading} type={newsList?.[0]?.type} />
    //                 <NewsItem data={newsList?.[1]} loading={loading} type={newsList?.[1]?.type} />
    //                 <NewsItem data={newsList?.[2]} loading={loading} type={newsList?.[2]?.type} />
    //                 <NewsItem data={newsList?.[3]} loading={loading} type={newsList?.[3]?.type} />
    //                 <RecommendedForYou data={store?.path?.dataAllVehicleTopList?.model} loading={loading} t={t} />
    //                 <HottestVideo hottestVideo={hottestVideoList} t={t} loading={loading}/>
    //                 <HomePageSpeedTest t={t} rankingList={store?.path?.dataSpeedTestRanking} loading={store?.path?.dataSpeedTestRanking == undefined ? true : false} />
    //                 {
    //                     !_.isEmpty(newsList) ?
    //                         _.reduce(newsList, (result, value, key) => {
    //                             key > 3 ? 
    //                             result.push(
    //                                 <div key={key}>
    //                                     <NewsItem data={value} type={value?.type} />
    //                                 </div> 
    //                             )
    //                             :
    //                             null
    //                             return result
    //                         },[])
    //                         :
    //                     null
    //                 }
    //                 {
    //                     _.isEmpty(newsList) &&
    //                     <EmptyData t={t} isLoading={isLoadingNewsList} />
    //                 }
    //                 {
    //                     (totalRow > (offset + pageLimit) && isLoadingNewsList == false) &&
    //                     <InView as="div" onChange={_handleLoadMore}>
    //                         {/* <LoadMore t={t} /> */}
    //                     </InView>
    //                 }
    //                 {
    //                     (isLoadingNewsList == true && totalRow != 0) &&
    //                     <LoadMore t={t} />
    //                 }
    //                 <Footer t={t} />
    //             </div>
    //             <BottomNavigation t={t} />
    //             <PlacementModal
    //                 placementModalOpen={placementModalOpen}
    //                 setPlacementModalOpen={setPlacementModalOpen}
    //                 t={t}
    //             >
    //                 <PlacementBanner
    //                     // can't pass object because inside is react memo, pass object wont work the memo
    //                     loading={store?.path?.dataPlacement == undefined ? true : false}
    //                     area={placementArea?.homePage_popUp} 
    //                     placement_id={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.placement_id}
    //                     type={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.type}
    //                     image_path={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.image_path}
    //                     url={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.url}
    //                     script={store?.path?.dataPlacement?.[placementArea?.homePage_popUp]?.script}
    //                 />
    //             </PlacementModal>
    //         </div>
    //         <LoginModal
    //             opened={loginOpened}
    //             setOpened={setLoginOpened}
    //             t={t}
    //         />
    //     </>
    // )
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
        revalidate: 5
    };
}